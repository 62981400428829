<template>
  <div id="divUsersManualHomeWrapper">
    <div ref="anchTopScroll"></div>
    <div class="video-wrapper">
      <BrandVimeoPlayer
        v-if="mediaData"
        :data="mediaData"
        :id="mediaData.videoId"
        :logger="{ interval: 5 }"
        @setPercentageValue="setPercentageValue($event)"
      />
    </div>
    <VueContentHolder class="video-list-wrapper" :padding="[20, 20]">
      <div v-for="item in mediaItems" :key="item.id">
        <BrandMediaListItem
          :text="item.title"
          :mediaSource="item"
          :showPercentage="false"
          :percentage="percentage"
          @handleRoute="handleVideoRouting(item)"
        />
      </div>
    </VueContentHolder>
  </div>
</template>
<script>
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import BrandMediaListItem from '@/components/brand/BrandMediaListItem/BrandMediaListItem.vue';
import vimeoUtils from '@/mixins/vimeoUtils.js';
import Content from '@/services/Api/content';

export default {
  name: 'UserManualNavigation',
  components: {
    VueContentHolder,
    BrandVimeoPlayer,
    BrandMediaListItem,
  },
  mixins: [vimeoUtils],
  data() {
    return {
      mediaData: null,
      mediaItems: [],
      percentage: 0,
      hasRefreshedAfter80: false,
    };
  },
  methods: {
    handleVideoRouting(item) {
      this.mediaData = null;
      setTimeout(() => {
        this.mediaData = item;
        this.$refs.anchTopScroll.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    },
    getUserGuideList() {
      const isFromRosette = this.$route.query.isFromRosette;

      Content.getUserGuide(isFromRosette ?? false).then(res => {
        const { videos } = res.data.Data;
        this.mediaItems = this.getVideoThumbs(videos);
        this.mediaData = videos[0];
        this.percentage = videos[0].log.percent;
        this.$route.meta.breadcrumb[2].name = videos[0]?.title ?? '';
      });
    },
    setPercentageValue(percentage) {
      this.percentage = percentage;
      if (
        percentage > 80 &&
        this.mediaData?.rosetteInput?.isRosetteTask &&
        !this.hasRefreshedAfter80
      ) {
        this.getUserGuideList();
        this.hasRefreshedAfter80 = true;
      }
    },
  },
  created() {
    this.getUserGuideList();
  },
};
</script>
<style lang="scss" scoped>
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  .video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
